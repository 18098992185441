var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var ButtonStyled = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font: bolder 18px \"Roboto Mono\", monospace;\n    border-radius: 7px;\n    min-width: 150px;\n    height: 50px;\n    cursor: pointer;\n    color: ", ";\n    background-color: ", ";\n    ", "\n    transition: all ease 0.2s;\n\n    &:hover {\n        scale: 1.02;\n    }\n\n    &:active {\n        scale: 0.98;\n    }\n\n    @media (max-width: 990px) {\n        min-width: 120px;\n        height: 40px;\n        font: bolder 15px \"Roboto Mono\", monospace;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font: bolder 18px \"Roboto Mono\", monospace;\n    border-radius: 7px;\n    min-width: 150px;\n    height: 50px;\n    cursor: pointer;\n    color: ", ";\n    background-color: ", ";\n    ", "\n    transition: all ease 0.2s;\n\n    &:hover {\n        scale: 1.02;\n    }\n\n    &:active {\n        scale: 0.98;\n    }\n\n    @media (max-width: 990px) {\n        min-width: 120px;\n        height: 40px;\n        font: bolder 15px \"Roboto Mono\", monospace;\n    }\n"])), function (_a) {
    var color = _a.color;
    return color || "#2b2b2b";
}, function (_a) {
    var bgColor = _a.bgColor;
    return bgColor || "#fcfcfc";
}, function (_a) {
    var borderColor = _a.borderColor;
    return borderColor
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                  border: 2px solid ", ";\n              "], ["\n                  border: 2px solid ", ";\n              "])), borderColor) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                  border: none;\n              "], ["\n                  border: none;\n              "])));
});
export var LinkButtonStyled = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: transparent;\n    font: bolder 18px \"Roboto Mono\", monospace;\n    border: none;\n    border-bottom: 2px solid transparent;\n    color: ", ";\n    cursor: pointer;\n    transition: all ease 0.2s;\n\n    &:hover {\n        border-bottom: 2px solid ", ";\n    }\n\n    @media (max-width: 990px) {\n        font: bolder 13px \"Roboto Mono\", monospace;\n    }\n"], ["\n    background: transparent;\n    font: bolder 18px \"Roboto Mono\", monospace;\n    border: none;\n    border-bottom: 2px solid transparent;\n    color: ", ";\n    cursor: pointer;\n    transition: all ease 0.2s;\n\n    &:hover {\n        border-bottom: 2px solid ", ";\n    }\n\n    @media (max-width: 990px) {\n        font: bolder 13px \"Roboto Mono\", monospace;\n    }\n"])), function (_a) {
    var color = _a.color;
    return color || "#335eea";
}, function (_a) {
    var color = _a.color;
    return color || "#335eea";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
