var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BurgerStyled from "../styles/Burger.styled";
var Burger = function (props) {
    return (_jsxs(BurgerStyled, __assign({ className: props.convertToCross ? "toggle" : "" }, { children: [_jsx("div", { className: "line1" }), _jsx("div", { className: "line2" }), _jsx("div", { className: "line3" })] })));
};
export default Burger;
