var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var NavbarStyled = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    z-index: 1000;\n    top: 0px;\n    display: flex;\n    width: 100vw;\n    max-width: 100%;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: rgb(252, 252, 252, 0.6);\n    backdrop-filter: blur(6px);\n\n    p {\n        color: #183819;\n    }\n"], ["\n    position: fixed;\n    z-index: 1000;\n    top: 0px;\n    display: flex;\n    width: 100vw;\n    max-width: 100%;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: rgb(252, 252, 252, 0.6);\n    backdrop-filter: blur(6px);\n\n    p {\n        color: #183819;\n    }\n"])));
export var NavbarInnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    padding: 0px 70px 0px 70px;\n\n    @media (max-width: 990px) {\n        padding: 0px 5px 0px 15px;\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    padding: 0px 70px 0px 70px;\n\n    @media (max-width: 990px) {\n        padding: 0px 5px 0px 15px;\n    }\n"])));
export var LogoButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: none;\n    cursor: pointer;\n    background-color: transparent;\n    min-width: 30%;\n"], ["\n    border: none;\n    cursor: pointer;\n    background-color: transparent;\n    min-width: 30%;\n"])));
export var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: flex-end;\n    max-width: 70%;\n"], ["\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: flex-end;\n    max-width: 70%;\n"])));
export var Menu = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: flex-end;\n\n    @media (max-width: 990px) {\n        display: none;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: flex-end;\n\n    @media (max-width: 990px) {\n        display: none;\n    }\n"])));
export var MenuButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font: 400 18px \"Roboto\", sans-serif;\n    letter-spacing: 3px;\n    transition: all ease 0.1s;\n    border: none;\n    padding: 3px 0px;\n    margin: 20px;\n    cursor: pointer;\n    background-color: transparent;\n    color: #2b2b2b;\n    border-bottom: 1px solid transparent;\n\n    &:active {\n        opacity: 0.7;\n    }\n\n    &:hover {\n        color: #555555;\n        border-bottom: 1px solid #555555;\n    }\n"], ["\n    font: 400 18px \"Roboto\", sans-serif;\n    letter-spacing: 3px;\n    transition: all ease 0.1s;\n    border: none;\n    padding: 3px 0px;\n    margin: 20px;\n    cursor: pointer;\n    background-color: transparent;\n    color: #2b2b2b;\n    border-bottom: 1px solid transparent;\n\n    &:active {\n        opacity: 0.7;\n    }\n\n    &:hover {\n        color: #555555;\n        border-bottom: 1px solid #555555;\n    }\n"])));
export var OpenMenuButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-width: 70px;\n    min-height: 80px;\n    font-size: 80px;\n\n    border: none;\n    cursor: pointer;\n    background-color: transparent;\n    color: #183819;\n\n    @media (min-width: 990px) {\n        display: none;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-width: 70px;\n    min-height: 80px;\n    font-size: 80px;\n\n    border: none;\n    cursor: pointer;\n    background-color: transparent;\n    color: #183819;\n\n    @media (min-width: 990px) {\n        display: none;\n    }\n"])));
export var NavbarMobileContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: transparent;\n    transform: ;\n    transition: transform 0.5s ease;\n\n    @media (min-width: 990px) {\n        display: none;\n    }\n"], ["\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: transparent;\n    transform: ;\n    transition: transform 0.5s ease;\n\n    @media (min-width: 990px) {\n        display: none;\n    }\n"])));
export var MenuButtonMobile = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    color: #2b2b2b;\n    border: none;\n    width: 100%;\n    font: 400 18px \"Poppins\", sans-serif;\n    letter-spacing: 3px;\n    padding: 10px 0px 10px 0px;\n    background-color: transparent;\n"], ["\n    color: #2b2b2b;\n    border: none;\n    width: 100%;\n    font: 400 18px \"Poppins\", sans-serif;\n    letter-spacing: 3px;\n    padding: 10px 0px 10px 0px;\n    background-color: transparent;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
