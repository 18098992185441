var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { FlexColumn } from "../styles/Utils.styled";
var TitleContainer = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: flex-start;\n    color: #2b2b2b;\n"], ["\n    align-items: flex-start;\n    color: #2b2b2b;\n"])));
var TitleSpan = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font: 500 21px \"Roboto\", sans-serif;\n    text-align: center;\n    letter-spacing: 3px;\n"], ["\n    font: 500 21px \"Roboto\", sans-serif;\n    text-align: center;\n    letter-spacing: 3px;\n"])));
var SubTitleSpan = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font: 100 11px \"Roboto\", sans-serif;\n    text-align: center;\n    letter-spacing: 2px;\n"], ["\n    font: 100 11px \"Roboto\", sans-serif;\n    text-align: center;\n    letter-spacing: 2px;\n"])));
var title = "stahl development";
var subtitle = "sustainable software engineering";
var Logo = function () {
    return (_jsx(_Fragment, { children: _jsxs(TitleContainer, { children: [_jsx(TitleSpan, { children: title }), _jsx(SubTitleSpan, { children: subtitle })] }) }));
};
export default Logo;
var templateObject_1, templateObject_2, templateObject_3;
