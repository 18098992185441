var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { FlexColumn, FlexRow } from "../styles/Utils.styled";
import { ArticleStyled, H3Styled } from "../styles/Text.styled";
var ParagraphContainer = styled(FlexRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    justify-content: flex-start;\n    height: 160px;\n    width: 100%;\n    gap: 20px;\n    border-radius: 7px;\n    ", "\n\n    &:hover {\n        .overlay {\n            background: #49458146;\n        }\n\n        img {\n            scale: 1.02;\n        }\n    }\n"], ["\n    justify-content: flex-start;\n    height: 160px;\n    width: 100%;\n    gap: 20px;\n    border-radius: 7px;\n    ", "\n\n    &:hover {\n        .overlay {\n            background: #49458146;\n        }\n\n        img {\n            scale: 1.02;\n        }\n    }\n"])), function (_a) {
    var reverse = _a.reverse;
    return reverse && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            flex-direction: row-reverse;\n        "], ["\n            flex-direction: row-reverse;\n        "])));
});
var ContentContainer = styled(FlexColumn)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 10px;\n    width: 100%;\n    height: 100%;\n\n    ", "\n"], ["\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 10px;\n    width: 100%;\n    height: 100%;\n\n    ", "\n"])), function (_a) {
    var reverse = _a.reverse;
    return reverse && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            align-items: flex-end;\n        "], ["\n            align-items: flex-end;\n        "])));
});
var ParagraphCard = function (props) {
    return (_jsxs(ContentContainer, { children: [_jsx(H3Styled, { children: props.title }), _jsx(ArticleStyled, { children: props.content })] }));
};
export default ParagraphCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
