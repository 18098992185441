var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { H6MonoStyled, Monospace } from "../styles/Text.styled";
import { FlexColumn } from "../styles/Utils.styled";
var ListingContainerStyled = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: flex-start;\n    gap: 5px;\n    color: #414bb2;\n"], ["\n    align-items: flex-start;\n    gap: 5px;\n    color: #414bb2;\n"])));
var ListingStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    list-style: none;\n\n    li {\n        border-bottom: 1px solid rgba(5, 5, 5, 0.096);\n        padding: 8px 0px;\n        min-width: 100px;\n        color: #2b2b2b;\n    }\n"], ["\n    list-style: none;\n\n    li {\n        border-bottom: 1px solid rgba(5, 5, 5, 0.096);\n        padding: 8px 0px;\n        min-width: 100px;\n        color: #2b2b2b;\n    }\n"])));
var Listing = function (props) {
    var heading = props.heading, items = props.items;
    return (_jsxs(ListingContainerStyled, { children: [_jsx(H6MonoStyled, { children: heading }), _jsx(ListingStyled, { children: items.map(function (item) { return (_jsx("li", { children: _jsx(Monospace, { children: item }) }, item)); }) })] }));
};
export default Listing;
var templateObject_1, templateObject_2;
