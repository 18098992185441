var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { FlexColumn, FlexRow, ImageContainer, ImageStyled, Overlay } from "../styles/Utils.styled";
import { ArticleStyled, H5Styled } from "../styles/Text.styled";
var CardContainer = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 350px;\n    border-radius: 5px;\n    overflow: hidden;\n    gap: 10px;\n\n    &:hover {\n        .overlay {\n            background: #49458146;\n        }\n\n        img.cover {\n            scale: 1.02;\n        }\n    }\n"], ["\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 350px;\n    border-radius: 5px;\n    overflow: hidden;\n    gap: 10px;\n\n    &:hover {\n        .overlay {\n            background: #49458146;\n        }\n\n        img.cover {\n            scale: 1.02;\n        }\n    }\n"])));
var LinkStyled = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font: lighter 14px \"Roboto Mono\", monospace;\n    color: ", ";\n    text-decoration: none;\n    border-bottom: 1px solid transparent;\n    transition: all ease 0.2s;\n    padding-bottom: 2px;\n\n    &:hover {\n        border-bottom: 1px solid ", ";\n    }\n"], ["\n    font: lighter 14px \"Roboto Mono\", monospace;\n    color: ", ";\n    text-decoration: none;\n    border-bottom: 1px solid transparent;\n    transition: all ease 0.2s;\n    padding-bottom: 2px;\n\n    &:hover {\n        border-bottom: 1px solid ", ";\n    }\n"])), function (_a) {
    var color = _a.color;
    return color || "#335eea";
}, function (_a) {
    var color = _a.color;
    return color || "#335eea";
});
var Content = styled(ArticleStyled)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: left;\n"], ["\n    text-align: left;\n"])));
var LinkContainer = styled(FlexRow)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    gap: 15px;\n"], ["\n    gap: 15px;\n"])));
var PortfolioCard = function (props) {
    return (_jsxs(CardContainer, { children: [_jsxs(ImageContainer, { children: [_jsx(ImageStyled, { className: "cover", src: props.image }), _jsx(Overlay, { className: "overlay" })] }), _jsx(H5Styled, { children: props.title }), _jsx(Content, { children: props.content }), props.children, _jsxs(LinkContainer, { children: [props.appUrl && (_jsx(LinkStyled, __assign({ href: props.appUrl, target: "_blank", rel: "noreferrer" }, { children: "project \u2192" }))), props.repoUrl && (_jsx(LinkStyled, __assign({ color: "#5e5e5e", href: props.repoUrl, target: "_blank", rel: "noreferrer" }, { children: "view code \u2192" })))] })] }));
};
export default PortfolioCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
