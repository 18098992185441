var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { ButtonContainer, FlexRow } from "../styles/Utils.styled";
import ContactMe from "./ContactMe";
import PlatformIcons from "./PlatformIcons";
import { Monospace } from "../styles/Text.styled";
var FooterStyled = styled(FlexRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100vw;\n    max-width: 100%;\n    min-height: 100px;\n    padding: 10px 80px;\n    background-color: #2b2b2b;\n    color: #fcfcfc;\n    justify-content: space-between;\n    @media (max-width: 990px) {\n        flex-direction: column-reverse;\n        gap: 10px;\n    }\n"], ["\n    width: 100vw;\n    max-width: 100%;\n    min-height: 100px;\n    padding: 10px 80px;\n    background-color: #2b2b2b;\n    color: #fcfcfc;\n    justify-content: space-between;\n    @media (max-width: 990px) {\n        flex-direction: column-reverse;\n        gap: 10px;\n    }\n"])));
var Footer = function () {
    return (_jsxs(FooterStyled, { children: [_jsx(Monospace, { children: "\u00A9 2023 stahl development" }), _jsx(ButtonContainer, { children: _jsx(ContactMe, { style: "white" }) }), _jsx(PlatformIcons, { color: "#fcfcfc" })] }));
};
export default Footer;
var templateObject_1;
