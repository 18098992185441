var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useCountdown } from "../hooks";
import { H1Styled, Monospace } from "../styles/Text.styled";
import { FlexColumn, FlexRow } from "../styles/Utils.styled";
var CarbonClockContainer = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .unit {\n        display: none;\n    }\n\n    @media (max-width: 990px) {\n        flex-direction: row;\n        align-items: space-between;\n        .title {\n            display: none;\n        }\n        .unit {\n            text-align: center;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n    }\n"], ["\n    .unit {\n        display: none;\n    }\n\n    @media (max-width: 990px) {\n        flex-direction: row;\n        align-items: space-between;\n        .title {\n            display: none;\n        }\n        .unit {\n            text-align: center;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n    }\n"])));
var TimeRemainingContainer = styled(FlexRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: space-around;\n    width: 100%;\n    padding: 0px 60px 0px 60px;\n\n    @media (max-width: 990px) {\n        height: 100%;\n        flex-direction: column;\n        justify-content: flex-end;\n        padding: 10px 0px 10px 0px;\n    }\n"], ["\n    align-items: space-around;\n    width: 100%;\n    padding: 0px 60px 0px 60px;\n\n    @media (max-width: 990px) {\n        height: 100%;\n        flex-direction: column;\n        justify-content: flex-end;\n        padding: 10px 0px 10px 0px;\n    }\n"])));
var TimeUnitContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-width: 100px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-width: 100px;\n"])));
var CarbonClock = function () {
    var remainingTime = useCountdown(1000, { temp: 1.5 }).remainingTime;
    return (_jsx(CarbonClockContainer, { children: _jsx(TimeRemainingContainer, { children: Object.entries(remainingTime).map(function (_a) {
                var key = _a[0], value = _a[1];
                return (_jsxs(TimeUnitContainer, { children: [_jsx(H1Styled, { children: value }), _jsx(Monospace, { children: key })] }, key));
            }) }) }));
};
export default CarbonClock;
var templateObject_1, templateObject_2, templateObject_3;
