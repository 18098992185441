var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var H1Styled = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font: bold 48px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"], ["\n    font: bold 48px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"])));
export var H2Styled = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font: lighter 48px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"], ["\n    font: lighter 48px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"])));
export var H2MonoStyled = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font: 400 30px \"Roboto Mono\", monospace;\n    @media (max-width: 990px) {\n        font: 400 15px \"Roboto Mono\", monospace;\n    }\n"], ["\n    font: 400 30px \"Roboto Mono\", monospace;\n    @media (max-width: 990px) {\n        font: 400 15px \"Roboto Mono\", monospace;\n    }\n"])));
export var H3Styled = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font: lighter 30px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"], ["\n    font: lighter 30px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"])));
export var H4Styled = styled.h4(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font: bolder 36px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"], ["\n    font: bolder 36px \"Roboto\", sans-serif;\n    letter-spacing: 2px;\n"])));
export var H5Styled = styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font: 300 18px \"Roboto\", sans-serif;\n"], ["\n    font: 300 18px \"Roboto\", sans-serif;\n"])));
export var H6Styled = styled.h6(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font: lighter 14px \"Roboto\", sans-serif;\n"], ["\n    font: lighter 14px \"Roboto\", sans-serif;\n"])));
export var H6MonoStyled = styled.h6(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font: lighter 14px \"Roboto Mono\", monospace;\n"], ["\n    font: lighter 14px \"Roboto Mono\", monospace;\n"])));
export var Monospace = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font: lighter 12px \"Roboto Mono\", monospace;\n"], ["\n    font: lighter 12px \"Roboto Mono\", monospace;\n"])));
export var ArticleStyled = styled.article(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font: 300 16px \"Roboto\", sans-serif;\n    line-height: 1.6;\n    text-align: justify;\n"], ["\n    font: 300 16px \"Roboto\", sans-serif;\n    line-height: 1.6;\n    text-align: justify;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
