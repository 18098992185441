var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var FlexCenter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"])));
export var FlexRow = styled(FlexCenter)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex-direction: row;\n"], ["\n    flex-direction: row;\n"])));
export var FlexColumn = styled(FlexCenter)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex-direction: column;\n"], ["\n    flex-direction: column;\n"])));
export var Container = styled(FlexColumn)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    justify-content: flex-start;\n    width: 100vw;\n    min-height: 100vh;\n    max-width: 100%;\n    color: ", ";\n    padding: 80px 0px;\n    background-color: ", ";\n"], ["\n    justify-content: flex-start;\n    width: 100vw;\n    min-height: 100vh;\n    max-width: 100%;\n    color: ", ";\n    padding: 80px 0px;\n    background-color: ", ";\n"])), function (_a) {
    var color = _a.color;
    return color || "#2b2b2b";
}, function (_a) {
    var bgColor = _a.bgColor;
    return bgColor || "#fcfcfc";
});
export var ButtonContainer = styled(FlexRow)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: flex-end;\n    justify-content: flex-start;\n    gap: 20px;\n"], ["\n    align-items: flex-end;\n    justify-content: flex-start;\n    gap: 20px;\n"])));
export var NavigationContainer = styled(FlexRow)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    align-items: flex-end;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 30px;\n    flex-wrap: wrap;\n    gap: 20px;\n\n    @media (max-width: 990px) {\n        justify-content: center;\n    }\n"], ["\n    align-items: flex-end;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 30px;\n    flex-wrap: wrap;\n    gap: 20px;\n\n    @media (max-width: 990px) {\n        justify-content: center;\n    }\n"])));
export var ImageContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    position: relative;\n    width: ", ";\n    height: ", ";\n    overflow: hidden;\n    margin-bottom: 5px;\n"], ["\n    position: relative;\n    width: ", ";\n    height: ", ";\n    overflow: hidden;\n    margin-bottom: 5px;\n"])), function (_a) {
    var size = _a.size;
    return (size === null || size === void 0 ? void 0 : size.width) || "100%";
}, function (_a) {
    var size = _a.size;
    return (size === null || size === void 0 ? void 0 : size.height) || "250px";
});
export var ImageStyled = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: all ease 0.1s;\n"], ["\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: all ease 0.1s;\n"])));
export var Overlay = styled(FlexColumn)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    align-items: center;\n    justify-content: flex-start;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #0b028821;\n    transition: all ease 0.2s;\n"], ["\n    align-items: center;\n    justify-content: flex-start;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #0b028821;\n    transition: all ease 0.2s;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
