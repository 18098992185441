var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonStyled } from "../styles/Button.styled";
var ContactMe = function (props) {
    return (_jsx(ButtonStyled, __assign({ onClick: function () { return (window.location.href = "mailto:max.stahl.de@gmail.com"); }, bgColor: props.style === "white" ? "#fcfcfc" : "#2b2b2b", color: props.style === "white" ? "#2b2b2b" : "#fcfcfc" }, { children: "contact me" })));
};
export default ContactMe;
