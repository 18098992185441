var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Cover from "../assets/hello.jpg";
import { TypeAnimation } from "react-type-animation";
import { H1Styled, H2Styled, H2MonoStyled } from "../styles/Text.styled";
import { ButtonStyled, LinkButtonStyled } from "../styles/Button.styled";
import ContactMe from "../components/ContactMe";
import handleOnClick from "../utils";
import { FlexColumn, FlexRow, ImageContainer, ImageStyled, Overlay } from "../styles/Utils.styled";
var HelloContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    width: 100vw;\n    height: 100vh;\n    max-width: 100%;\n    overflow: hidden;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    width: 100vw;\n    height: 100vh;\n    max-width: 100%;\n    overflow: hidden;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    gap: 50px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 80%;\n    height: 80%;\n    margin: 70px 40px 0px 40px;\n\n    color: #fcfcfc;\n\n    @media (max-width: 990px) {\n        align-items: flex-start;\n    }\n"], ["\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    gap: 50px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 80%;\n    height: 80%;\n    margin: 70px 40px 0px 40px;\n\n    color: #fcfcfc;\n\n    @media (max-width: 990px) {\n        align-items: flex-start;\n    }\n"])));
var Intro = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    min-height: 30%;\n"], ["\n    min-height: 30%;\n"])));
var Claim = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var Navigation = styled(FlexRow)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 30px;\n    width: 100%;\n\n    @media (max-width: 990px) {\n        justify-content: center;\n    }\n"], ["\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 30px;\n    width: 100%;\n\n    @media (max-width: 990px) {\n        justify-content: center;\n    }\n"])));
var Banner = styled(FlexColumn)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    justify-content: space-between;\n    position: absolute;\n    padding: 15px;\n    bottom: 0;\n    width: 30%;\n    height: 150px;\n    background-color: #fcfcfc;\n    border-radius: 15px 15px 0px 0px;\n    backdrop-filter: blur(7px);\n    text-align: center;\n    margin-bottom: -50px;\n    transition: all ease 0.5s;\n\n    &:hover {\n        margin-bottom: 0px;\n    }\n\n    @media (max-width: 990px) {\n        width: 80%;\n        margin-bottom: 0px;\n        height: 70px;\n        justify-content: space-between;\n    }\n"], ["\n    justify-content: space-between;\n    position: absolute;\n    padding: 15px;\n    bottom: 0;\n    width: 30%;\n    height: 150px;\n    background-color: #fcfcfc;\n    border-radius: 15px 15px 0px 0px;\n    backdrop-filter: blur(7px);\n    text-align: center;\n    margin-bottom: -50px;\n    transition: all ease 0.5s;\n\n    &:hover {\n        margin-bottom: 0px;\n    }\n\n    @media (max-width: 990px) {\n        width: 80%;\n        margin-bottom: 0px;\n        height: 70px;\n        justify-content: space-between;\n    }\n"])));
var TextAnimationContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n\n    @media (max-width: 990px) {\n        flex-direction: column;\n    }\n\n    .type-animation {\n        font: bolder 48px \"Roboto\", sans-serif;\n        @media (max-width: 990px) {\n            font: bolder 30px \"Roboto\", sans-serif;\n        }\n    }\n\n    .type-animation::after {\n        font: lighter 48px \"Roboto\", sans-serif;\n        @media (max-width: 990px) {\n            font: bolder 30px \"Roboto\", sans-serif;\n        }\n    }\n"], ["\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n\n    @media (max-width: 990px) {\n        flex-direction: column;\n    }\n\n    .type-animation {\n        font: bolder 48px \"Roboto\", sans-serif;\n        @media (max-width: 990px) {\n            font: bolder 30px \"Roboto\", sans-serif;\n        }\n    }\n\n    .type-animation::after {\n        font: lighter 48px \"Roboto\", sans-serif;\n        @media (max-width: 990px) {\n            font: bolder 30px \"Roboto\", sans-serif;\n        }\n    }\n"])));
var HelloH2Styled = styled(H2Styled)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    @media (max-width: 990px) {\n        font: lighter 30px \"Roboto\", sans-serif;\n    }\n"], ["\n    @media (max-width: 990px) {\n        font: lighter 30px \"Roboto\", sans-serif;\n    }\n"])));
var HelloH1Styled = styled(H1Styled)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    @media (max-width: 990px) {\n        font: bold 30px \"Roboto\", sans-serif;\n    }\n"], ["\n    @media (max-width: 990px) {\n        font: bold 30px \"Roboto\", sans-serif;\n    }\n"])));
var Hello = function () {
    return (_jsxs(HelloContainer, __assign({ id: "hello" }, { children: [_jsxs(ImageContainer, __assign({ size: { width: "100vw", height: "100vh" } }, { children: [_jsx(ImageStyled, { src: Cover }), _jsx(Overlay, {})] })), _jsxs(Content, { children: [_jsxs(Intro, { children: [_jsx(HelloH1Styled, { children: "Hello" }), _jsxs(TextAnimationContainer, { children: [_jsx(HelloH2Styled, { children: "I Am " }), _jsx(TypeAnimation, { className: "type-animation", sequence: [
                                            "Max Stahl",
                                            2500,
                                            "Freelancer",
                                            1500,
                                            "Senior Backend Developer",
                                            1500,
                                            "Sustainable Software Engineer",
                                            1500,
                                        ], repeat: Infinity })] })] }), _jsxs(Claim, { children: [_jsxs(HelloH2Styled, { children: ["Let`s collaborate and realize your next", _jsx("br", {}), " software project!"] }), _jsx(HelloH1Styled, { children: "on time. sustainable." })] }), _jsxs(Navigation, { children: [_jsx(ContactMe, { style: "white" }), _jsx(ButtonStyled, __assign({ onClick: function () { return handleOnClick("about_me"); }, borderColor: "#fcfcfc", color: "#fcfcfc", bgColor: "transparent" }, { children: "about me" }))] })] }), _jsxs(Banner, { children: [_jsx(H2MonoStyled, { children: "sustainability in software?" }), _jsx(LinkButtonStyled, __assign({ onClick: function () { return handleOnClick("sustainability"); } }, { children: "learn more" }))] })] })));
};
export default Hello;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
