// @ts-ignore
import dayjs from "dayjs";
var countdown = require("./lib/countdown");
// Constants
var millisecondsPerYear = 3600 * 24 * 365.25 * 1000;
var startDate = dayjs("2020-02-01");
var initialAnnualEmissions = 42.2 * 1e9;
// Top level function.
// Calculates remaining co2-budget and time until it's depleted
export function calcRemaining(conf) {
    var totalBudget = getTotalBudget(conf);
    var remainingBudget = getRemainingBudget(totalBudget);
    var remainingTime = getRemainingTime(totalBudget);
    return { remainingBudget: remainingBudget, remainingTime: remainingTime };
}
// Returns seconds passed since a given date
function secondsPassed(date) {
    var secondsPassed = dayjs().diff(date, "seconds");
    return secondsPassed;
}
// Calculates remaining budget using interpolation
function getRemainingBudget(totalBudget) {
    var budgetUsed = (secondsPassed(startDate) / millisecondsPerYear) * initialAnnualEmissions;
    return totalBudget - budgetUsed;
}
// Calculates time until budget is depleted, using interpolation
function getRemainingTime(totalBudget) {
    var yearsBudget = totalBudget / initialAnnualEmissions;
    var endDate = dayjs(startDate).add(yearsBudget * millisecondsPerYear, "milliseconds");
    var cdown = countdown(endDate);
    // Destructure cdown into object containing times
    var times = (function (_a) {
        var years = _a.years, months = _a.months, days = _a.days, hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds;
        return ({
            years: years,
            months: months,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        });
    })(cdown);
    return times;
}
function getTotalBudget(conf) {
    switch (conf.temp) {
        case 1.5:
            return 400 * 1e9;
        case 2:
            return 1150 * 1e9;
    }
}
