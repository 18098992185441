var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { FlexCenter, FlexColumn, FlexRow, Container, NavigationContainer, ButtonContainer, } from "../styles/Utils.styled";
import { ArticleStyled, H1Styled, H4Styled, H6Styled } from "../styles/Text.styled";
import ProfileImage from "../assets/about_me.jpg";
import Listing from "../components/Listing";
import { ButtonStyled } from "../styles/Button.styled";
import PlatformIcons from "../components/PlatformIcons";
import ContactMe from "../components/ContactMe";
import handleOnClick from "../utils";
var ContentWrapper = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 1450px;\n"], ["\n    max-width: 1450px;\n"])));
var InnerContainer = styled(FlexRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: flex-start;\n    gap: 40px;\n    width: 100vw;\n    margin-top: 80px;\n    flex-wrap: wrap;\n    padding: 0px 20px;\n\n    @media (max-width: 990px) {\n        min-height: 100vh;\n        align-items: center;\n    }\n"], ["\n    align-items: flex-start;\n    gap: 40px;\n    width: 100vw;\n    margin-top: 80px;\n    flex-wrap: wrap;\n    padding: 0px 20px;\n\n    @media (max-width: 990px) {\n        min-height: 100vh;\n        align-items: center;\n    }\n"])));
var ImageContainer = styled(FlexCenter)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    width: 450px;\n    min-height: 550px;\n    border-radius: 7px;\n    overflow: hidden;\n    flex-shrink: 2;\n"], ["\n    position: relative;\n    width: 450px;\n    min-height: 550px;\n    border-radius: 7px;\n    overflow: hidden;\n    flex-shrink: 2;\n"])));
var ImageStyled = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    left: 0;\n    object-fit: cover;\n    height: 100%;\n    width: 100%;\n"], ["\n    position: absolute;\n    top: 0;\n    left: 0;\n    object-fit: cover;\n    height: 100%;\n    width: 100%;\n"])));
var ContentContainer = styled(FlexColumn)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 50%;\n    min-height: 550px;\n    gap: 10px;\n\n    @media (max-width: 990px) {\n        width: 90%;\n    }\n"], ["\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 50%;\n    min-height: 550px;\n    gap: 10px;\n\n    @media (max-width: 990px) {\n        width: 90%;\n    }\n"])));
var HeaderContainer = styled(FlexRow)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    color: #2b2b2b;\n    gap: 5px;\n\n    @media (max-width: 990px) {\n        gap: 0px;\n    }\n"], ["\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    color: #2b2b2b;\n    gap: 5px;\n\n    @media (max-width: 990px) {\n        gap: 0px;\n    }\n"])));
var SubHeaderContainer = styled(FlexColumn)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    align-items: flex-start;\n"], ["\n    align-items: flex-start;\n"])));
var ListingContainer = styled(FlexRow)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    justify-content: space-between;\n    align-items: flex-start;\n    padding-top: 10px;\n    flex-wrap: wrap;\n    width: 100%;\n    gap: 20px;\n"], ["\n    justify-content: space-between;\n    align-items: flex-start;\n    padding-top: 10px;\n    flex-wrap: wrap;\n    width: 100%;\n    gap: 20px;\n"])));
var AboutMe = function () {
    return (_jsxs(Container, __assign({ id: "about_me" }, { children: [_jsx(H1Styled, { children: "about me." }), _jsxs(InnerContainer, { children: [_jsx(ImageContainer, { children: _jsx(ImageStyled, { src: ProfileImage }) }), _jsxs(ContentContainer, { children: [_jsxs(HeaderContainer, { children: [_jsx(H4Styled, { children: "Max Stahl" }), _jsxs(SubHeaderContainer, { children: [_jsx(H6Styled, { children: "Senior Backend Developer" }), _jsx(H6Styled, { children: "Sustainable Software Engineer" })] })] }), _jsxs(ArticleStyled, { children: ["I'm a backend developer with 8+ years of experience as a Developer, Solution Architect, and Team Lead for Devs and DevOps. My diverse experience gives me a unique perspective on software development, and I am a passionate advocate for sustainable and ethical practices. With strong communication skills and exceptional teamwork abilities, I am committed to collaborating effectively and delivering high-quality solutions. ", _jsx("br", {}), " ", _jsx("br", {}), "Here is a selection of technologies, tools and methods I have worked with:"] }), _jsxs(ListingContainer, { children: [_jsx(Listing, { heading: "Methods", items: [
                                            "Agile Development",
                                            "eXtreme Programming",
                                            "Servant Leadership",
                                            "Test-Driven-Development",
                                            "Sustainability",
                                        ] }), _jsx(Listing, { heading: "Backend", items: ["Java", "Spring-Boot", "REST", "Spring", "Maven", "Mockito", "JUnit"] }), _jsx(Listing, { heading: "DevOps", items: ["Git", "Docker", "GitHub Actions", "Jenkins", "Prometheus", "Grafana"] }), _jsx(Listing, { heading: "Frontend", items: ["React", "JavaScript", "TypeScript", "CSS / HTML"] })] }), _jsxs(NavigationContainer, { children: [_jsxs(ButtonContainer, { children: [_jsx(ContactMe, { style: "black" }), _jsx(ButtonStyled, __assign({ onClick: function () { return handleOnClick("portfolio"); }, bgColor: "transparent", color: "#2b2b2b", borderColor: "#2b2b2b" }, { children: "portfolio" }))] }), _jsx(PlatformIcons, {})] })] })] })] })));
};
export default AboutMe;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
