import { useEffect, useRef, useState } from "react";
import { calcRemaining } from "../carbonBudgetCalc";
export function usePrevious(value) {
    // Use ref to store value
    var ref = useRef();
    // Update ref when value changes
    useEffect(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
}
export function useCountdown(updateInterval, conf) {
    var _a = useState(calcRemaining(conf)), remaining = _a[0], setRemaining = _a[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setRemaining(calcRemaining(conf));
        }, updateInterval);
        // Clear timeout before the component is unmounted
        return function () { return clearInterval(interval); };
    }, [updateInterval, conf]);
    return remaining;
}
export var useThrottle = function (value, fps) {
    if (fps === void 0) { fps = 30; }
    var _a = useState(value), throttledValue = _a[0], setThrottledValue = _a[1];
    var lastRan = useRef(Date.now());
    var ms = 1000 / fps;
    useEffect(function () {
        var timeoutCallback = function () {
            if (Date.now() - lastRan.current >= ms) {
                setThrottledValue(value);
                lastRan.current = Date.now();
            }
        };
        var duration = ms - (Date.now() - lastRan.current);
        var timeout = setTimeout(timeoutCallback, duration);
        return function () {
            clearTimeout(timeout);
        };
    }, [value, ms]);
    return throttledValue;
};
