var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftContainer, NavbarStyled, Menu, MenuButton, OpenMenuButton, LogoButton, NavbarInnerContainer, NavbarMobileContainer, MenuButtonMobile, } from "../styles/Navbar.styled";
import { useState } from "react";
import Burger from "./Burger";
import handleOnClick from "../utils";
import Logo from "./Logo";
var Navbar = function () {
    var _a = useState(false), openMobileMenu = _a[0], setOpenMobileMenu = _a[1];
    var handleClick = function (elementId) {
        handleOnClick(elementId, function () { return setOpenMobileMenu(function (curr) { return !curr; }); });
    };
    return (_jsxs(NavbarStyled, { children: [_jsxs(NavbarInnerContainer, { children: [_jsx(LogoButton, __assign({ onClick: function () { return handleOnClick("hello"); } }, { children: _jsx(Logo, {}) })), _jsxs(LeftContainer, { children: [_jsxs(Menu, { children: [_jsx(MenuButton, __assign({ onClick: function () { return handleOnClick("about_me"); } }, { children: "about me" })), _jsx(MenuButton, __assign({ onClick: function () { return handleOnClick("portfolio"); } }, { children: "portfolio" })), _jsx(MenuButton, __assign({ onClick: function () { return handleOnClick("sustainability"); } }, { children: "sustainability" }))] }), _jsx(OpenMenuButton, __assign({ onClick: function () {
                                    setOpenMobileMenu(function (curr) { return !curr; });
                                } }, { children: _jsx(Burger, { convertToCross: openMobileMenu }) }))] })] }), openMobileMenu && (_jsxs(NavbarMobileContainer, { children: [_jsx(MenuButtonMobile, __assign({ onClick: function () { return handleClick("about_me"); } }, { children: "about me" })), _jsx(MenuButtonMobile, __assign({ onClick: function () { return handleClick("portfolio"); } }, { children: "portfolio" })), _jsx(MenuButtonMobile, __assign({ onClick: function () { return handleClick("sustainability"); } }, { children: "sustainability" }))] }))] }));
};
export default Navbar;
