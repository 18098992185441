var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { FlexColumn } from "./styles/Utils.styled";
import AboutMe from "./views/AboutMe";
import Hello from "./views/Hello";
import Portfolio from "./views/Portfolio";
import Sustainability from "./views/Sustainability";
var AppContainer = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100vw;\n    max-width: 100%;\n    overflow: hidden;\n"], ["\n    width: 100vw;\n    max-width: 100%;\n    overflow: hidden;\n"])));
var App = function () {
    return (_jsxs(AppContainer, { children: [_jsx(Navbar, {}), _jsx(Hello, {}), _jsx(AboutMe, {}), _jsx(Portfolio, {}), _jsx(Sustainability, {}), _jsx(Footer, {})] }));
};
export default App;
var templateObject_1;
